.main-div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-top: 10vh;
}
.holder-div {
    align-self: start;
    margin: 2vh 2vh 2vh;
}
.image-div {
    align-items: center;
    align-self: center;
}
.self-image {
    min-width: 25vh;
    min-height: 25vh;
    margin-bottom: 2vh;
}
.socials {
    justify-content: space-evenly;
    display: flex;
}