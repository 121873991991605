.info-div {
    flex: 1;
    flex-direction: row;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    background: linear-gradient(0.25turn, #332835, #312633  );
    padding: 1vh 0;
    border-radius: 30px;
    box-shadow: 10px 5px 5px #111111;
    margin-bottom: 5vh;
}
.header-div {
    margin: 3vh 0 2vh;
}

.carousel {
    aspect-ratio: 16/9 !important;
    max-width:42vw;
    min-width: 200px;
    display: flex;
    align-self: center;
    justify-self: center;
    margin: 0 0;
}
.player {
    aspect-ratio: 16/9 !important;
    padding: 0 0 0;
}
.image {
    aspect-ratio: 16/9 !important;
}
.media-wrapper {
}
div.media-wrapper > img {
    max-width: 100%;
    max-height: 100%;
}
div.media-wrapper > ReactPlayer {
    max-width: 100%;
    max-height: 100%;
    text-align: center;
}
.text-holder {
    justify-content: center;
    align-self: center;
    max-width: 40vh;
    margin: 0 0;
}
.text-wrapper {
    background-color: pink;
    text-align: center;
    display: flow;
}
.button {
    width: 300px;
    height: 100px;
    margin: 1vh 3vw;


}
.button-holder {
    width: 100%;
     justify-content: space-evenly;
}
.react-player {
    .react-player {
        padding-top: 56.25%;
    position: relative;
    }
}

.react-player > div {
    position: absolute;
}
.video-wrapper {
    height: 100%;
    width: 100%;
    min-height: 225px;
}