.main-div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: initial;
    width: 100%;
}
.demovid-container {
    padding-top: 1vh;
    justify-self: center;
    align-self: center;
    max-width: 35vw;
    min-width: 400px;
}
.row-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    flex-wrap: wrap;
    align-self: center;
    justify-self: center;
}