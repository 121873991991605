.paragraph {
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    color: floralwhite;
}
.header {
    font-family: 'Montserrat', sans-serif;
    color: white;
}
.hyperlink {
    font-family: 'Raleway', sans-serif;
    color: #f0b0ff;
}
.hyperlink:hover {
    color: #ecc4ff;
}
.button {
    border-radius: 40px;
    width: 100px;
    height: 50px;
    background-color: #4e4b3f;
    border: none;
}
.button:hover {
    background-color: #3a362f;
}
.button:focus {
    background-color: #332f29;

}
.hyperlink-header {
    color: antiquewhite;
    font-family: 'Montserrat', sans-serif;
}
.hyperlink-header:hover {
    color: #ecc4ff;
}