.main-container {
    background: linear-gradient(0.25turn, #332835, #312633  );
    flex: 1;
    text-align: left;
    padding: 1vh 1vw 0;
    border-radius: 15px;
    margin: 1vh 0 0;
    box-shadow: 10px 5px 5px #111111;
    text-shadow: orange;
    }
.info-container {
    flex-direction: row;
    flex: 1;
    display: flex;

}
.player {
    flex-grow: 1;
    height: auto !important;
    aspect-ratio: 16/9;
    min-width: 350px;
    margin-left: auto;
    margin-right: auto;
}
.player-wrapper {
    justify-content: center;
    align-items: center;
    display: flex;
    box-shadow: #111111;

}
.desc-div {
    flex: 1;
    text-align: left;
    margin-top: 5px;
    margin-bottom: 0;
    }
.text {
}
.header {
}